import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 30,
  },
  container: {
    margin: "8px 10px 18px 0px",
  },
  slider: {
    width: 300,
  },
  inputBoxContainer: {
    padding: "38px 8px 8px 8px"
  },
  inputBox: {
    width: 90
  }
}));


export default function PrincipleSlider(props) {
  const classes = useStyles();

  const handlePrincipleBlur = () => {
    if (props.principle < 0) {
      props.setPrinciple(0);
    } else if (props.principle > 5000) {
      props.setPrinciple(5000);
    }
  };

  const handlePrincipleSliderChange = (event, newValue) => {
    props.setPrinciple(newValue);
  };

  const handlePrincipleInputChange = (event) => {
    props.setPrinciple(event.target.value === '' ? '' : Number(event.target.value));
  };

  return (
    <Grid container spacing={2} alignItems="center" className={classes.container}>
      <Typography gutterBottom>
        Principle
      </Typography>

      <Grid item>
        <Slider
          value={props.principle}
          min={100}
          step={100}
          max={5000}
          onChange={handlePrincipleSliderChange}
          className={classes.slider}
        />
      </Grid>
      <Grid item className={classes.inputBoxContainer}>
        <Input
          className={classes.inputBox}
          value={props.principle}
          margin="dense"
          onChange={handlePrincipleInputChange}
          onBlur={handlePrincipleBlur}
          inputProps={{
            step: 10,
            min: 100,
            max: 5000,
            type: 'number',
          }}
          startAdornment={
            <InputAdornment position="start">$</InputAdornment>
          }
        />
      </Grid>
    </Grid>
  )

}
