import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {ExportToCsv} from "export-to-csv";

const useStyles = makeStyles((theme) => ({}));


export default function ExportDialog(props) {
  const classes = useStyles();

  const [filename, setFilename] = useState("");
  const [error, setError] = useState(false);

  const handleExport = () => {

    if (filename.includes(".") || filename.length === 0) {
      setError(true);
      return;
    }

    const options = {
      fieldSeparator: ',',
      filename: filename,
      title: filename,
    };

    let data = [...props.tableData];

    const headers = {
      id: "ID",
      paymentDate: "Payment Date",
      principle: "Balance",
      totalPayment: "Payment",
      interestPayment: "Interest",
      principlePayment: "Principle",
      newPrinciple: "Debt Remaining"
    };

    data.unshift(headers);

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
    props.setOpen(false);
  }

  const handleFileNameChange = (event) => {
    setError(false);
    setFilename(event.target.value);
  }


  return (
    <div>
      <Dialog fullWidth={true} maxWidth={'sm'} open={props.open} onClose={() => {
        props.setOpen(false)
      }}>
        <DialogTitle>Download</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter filename:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="File Name"
            type="text"
            fullWidth
            helperText={"Do not include file extension"}
            placeholder={"PiggyDebtContract"}
            onChange={handleFileNameChange}
            error={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            props.setOpen(false)
          }} color="primary">
            Cancel
          </Button>
          <Button onClick={handleExport} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>)

}
