import moment from "moment";

export default function CalculateData(principle, periodicPayment, interestRate, ratePerPeriod, numberOfPayments, startDate) {

  let paymentDate = moment(startDate);

  let interest = interestRate / 100;

  if (!interest) {
    interest = 0;
  }

  const data = [];
  let numberOfDates;
  let typeOfDates;
  switch (ratePerPeriod) {
    case 365:
      numberOfDates = 1;
      typeOfDates = "days";
      break;
    case 52:
      numberOfDates = 1;
      typeOfDates = "weeks";
      break;
    case 26:
      numberOfDates = 2;
      typeOfDates = "weeks";
      break;
    case 12:
      numberOfDates = 1;
      typeOfDates = "months";
      break;
    case 6:
      numberOfDates = 2;
      typeOfDates = "months";
      break;
    default: // default to weekly dates I guess?
      numberOfDates = 1;
      typeOfDates = "weeks";
      break;
  }


  let count = 1;
  while (data.length < numberOfPayments) {
    const interestPayment = (principle * interest).toFixed(2);
    let principlePayment = (periodicPayment - interestPayment).toFixed(2);
    let newPrinciple = (principle - principlePayment).toFixed(2);
    newPrinciple = Math.max(+newPrinciple, 0);

    if (newPrinciple < periodicPayment * .1) {
      principlePayment = (+principlePayment + +newPrinciple).toFixed(2);
      periodicPayment = periodicPayment + newPrinciple;
      newPrinciple = 0;
    }

    data.push({
      id: count,
      principle: "$" + principle,
      interestPayment: "$" + interestPayment,
      principlePayment: "$" + principlePayment,
      newPrinciple: "$" + newPrinciple,
      paymentDate: paymentDate.format("MMM Do YYYY"),
      totalPayment: "$" + periodicPayment.toFixed(2)
    });
    principle = newPrinciple;
    count++;
    paymentDate = paymentDate.add(numberOfDates, typeOfDates);
  }

  return data;
}
