import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerItem: {
    marginTop: 0,
    overflow: "scroll",
    paddingRight: 30
  },
  container: {
    paddingRight: 30,
    overflow: "scroll"
  }
}));


export default function PaybackToggle(props) {
  const classes = useStyles();

  const handleAlignment = (event, newPaybackRate) => {
    if (newPaybackRate !== null) {
      props.setPaybackRate(newPaybackRate);
    }
  };

  return (
    <Grid container className={classes.container} spacing={2} alignItems="center">
      <Grid item className={classes.containerItem}>
        <Typography variant="body1">
          Payback Frequency
        </Typography>
        <ToggleButtonGroup
          value={props.paybackRate}
          exclusive
          onChange={handleAlignment}
        >
          <ToggleButton value="EveryDay">
            Every Day
          </ToggleButton>
          <ToggleButton value="EveryWeek">
            Every Week
          </ToggleButton>
          <ToggleButton value="EveryOtherWeek">
            Every other Week
          </ToggleButton>
          <ToggleButton value="EveryMonth">
            Every Month
          </ToggleButton>
          <ToggleButton value="EveryOtherMonth">
            Every Other Month
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  )

}
