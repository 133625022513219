import React, {useState} from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import PaybackToggle from "./PaybackToggle";
import "react-datepicker/dist/react-datepicker.css";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DebtLengthSlider from "./DebtLengthSlider";
import InterestSlider from "./InterestSlider";
import PrincipleSlider from "./PrincipleSlider";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {ThemeProvider} from "@material-ui/styles";
import NumberFormat from "react-number-format";
import {DatePicker} from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";
import Fab from "@material-ui/core/Fab";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ExportDialog from "./ExportDialog";
import CalculateData from "./CalculateData";
import AmortizationTable from "./AmortizationTable";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Nunito',
      'sans-serif',
    ].join(','),
  },
});


const useStyles = makeStyles(() => ({
  paper: {
    padding: '6px 16px',
    position: "relative"
  },
  input: {
    width: 300,
  },
  monthlyPaymentLabel: {
    marginTop: 25,
  },
  glass: {
    background: "rgba( 255, 255, 255, 0.50 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 10.5px )",
    borderRadius: "10px",
    padding: '6px 16px',
    marginTop: 40
  },
  fab: {
    position: "fixed",
    bottom: "30px",
    right: "30px"
  },
  datePicker: {
    marginTop: 40
  },
  alert: {
    marginTop: 30
  }
}));

export default function App() {
  const classes = useStyles();

  const [principle, setPrinciple] = useState(1000);
  const [interestRate, setInterestRate] = useState(15);
  const [paybackRate, setPaybackRate] = useState("EveryMonth");
  const [debtLength, setDebtLength] = useState(6);
  const [startDate, setStartDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  let ratePerPeriod = 0;
  let periodicPaymentLabel = "Monthly"

  const calculateNumberOfPayments = () => {
    switch (paybackRate) {
      case "EveryDay":
        ratePerPeriod = 365;
        periodicPaymentLabel = "Daily";
        return Math.ceil(365 * debtLength / 12);
      case "EveryWeek":
        ratePerPeriod = 52;
        periodicPaymentLabel = "Weekly";
        return Math.ceil(52 * debtLength / 12);
      case "EveryOtherWeek":
        ratePerPeriod = 26;
        periodicPaymentLabel = "Bi-weekly"
        return Math.ceil(26 * debtLength / 12);
      case "EveryMonth":
        ratePerPeriod = 12
        periodicPaymentLabel = "Monthly"
        return debtLength;
      case "EveryOtherMonth":
        ratePerPeriod = 6;
        periodicPaymentLabel = "Bi-monthly"
        return Math.ceil(debtLength / 2);
      default:
        console.error("unrecognized payback rate: " + paybackRate);
        return 0;
    }
  }

  let numberOfPayments = calculateNumberOfPayments();
  let middle = (Math.pow(1 + interestRate / 100, numberOfPayments) - 1);
  if (!middle) middle = 0;

  let bottom = (interestRate / 100 * Math.pow(1 + interestRate / 100, numberOfPayments));
  if (!bottom) bottom = 0;

  let periodicPayment;

  if (interestRate === 0) {
    periodicPayment = principle / numberOfPayments;
  } else {
    periodicPayment = principle / (middle / bottom);
  }

  const handleExportClicked = () => {
    setOpen(true);
  }

  let tableData = CalculateData(principle, periodicPayment, interestRate, ratePerPeriod, numberOfPayments, startDate);


  return (
    <ThemeProvider theme={theme}>

      <Container maxWidth="md">
        <Paper elevation={3} className={classes.glass}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <PrincipleSlider principle={principle} setPrinciple={setPrinciple}/>
            <InterestSlider interestRate={interestRate} setInterestRate={setInterestRate}/>
            <DebtLengthSlider debtLength={debtLength} setDebtLength={setDebtLength}/>

            <PaybackToggle paybackRate={paybackRate} setPaybackRate={setPaybackRate}/>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <DatePicker
                className={classes.datePicker}
                label="First Payment Date"
                value={startDate}
                onChange={date => setStartDate(date)}
                disablePast={true}
                autoOk
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.monthlyPaymentLabel}>
            <Grid item>
              <Typography>

                {periodicPaymentLabel} Payment: <NumberFormat value={periodicPayment.toFixed(2)} displayType={'text'}
                                                              thousandSeparator={true} prefix={'$'}/>
              </Typography>
              <br/>
              <Typography>
                Total Debt Amount: <NumberFormat value={(periodicPayment.toFixed(2) * numberOfPayments).toFixed(2)}
                                                 displayType={'text'}
                                                 thousandSeparator={true} prefix={'$'}/>
              </Typography>
              <br/>
              <Typography>
                Number of payments: <NumberFormat value={numberOfPayments} displayType={'text'}
                                                  thousandSeparator={true}/>
              </Typography>
              <Alert severity="info" className={classes.alert}>Interest is compounded {periodicPaymentLabel}</Alert>

            </Grid>
          </Grid>


          <AmortizationTable tableData={tableData}/>

        </Paper>


      </Container>
      <Fab color="primary" className={classes.fab} onClick={handleExportClicked}>
        <SaveAltIcon/>
      </Fab>

      <ExportDialog open={open} setOpen={setOpen} tableData={tableData}/>

    </ThemeProvider>
  )
}
