import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
  },
  input: {
    width: 300,
  },
  container: {
    margin: "8px 10px 18px 0px",
  },
}));


export default function DebtLengthSlider(props) {
  const classes = useStyles();

  const handleDebtLengthBlur = () => {
    if (props.debtLength < 1) {
      props.setDebtLength(1);
    } else if (props.debtLength > 24) {
      props.setDebtLength(24)
    }
  }

  const handleDebtLengthSliderChange = (event, newValue) => {
    props.setDebtLength(newValue);
  }

  const handleDebtLengthInputChange = (event) => {
    props.setDebtLength(event.target.value);
  }

  return (
    <Grid container spacing={2} alignItems="center" className={classes.container}>
      <Typography gutterBottom>
        Debt Length
      </Typography>

      <Grid item>
        <Slider
          value={props.debtLength}
          min={1}
          step={1}
          max={24}
          onChange={handleDebtLengthSliderChange}
          className={classes.input}
        />
      </Grid>
      <Grid item>
        <Input
          value={props.debtLength}
          margin="dense"
          onChange={handleDebtLengthInputChange}
          onBlur={handleDebtLengthBlur}
          inputProps={{
            step: 1,
            min: 1,
            max: 24,
            type: 'number',
          }}
          endAdornment={
            <InputAdornment position="end">Months</InputAdornment>
          }
        />
      </Grid>
    </Grid>
  )
}
