import React from 'react';
import {LicenseInfo, XGrid} from "@material-ui/x-grid";

LicenseInfo.setLicenseKey(
  '4ed0c41fcffc15af3191cbcba2ae4b45T1JERVI6MTk4MjcsRVhQSVJZPTE2NDA5NjU5NTEwMDAsS0VZVkVSU0lPTj0x',
);

export default function AmortizationTable(props) {

  const columns = [
    {field: 'id', headerName: '', flex: 0.8, sortable: false},
    {field: 'paymentDate', headerName: 'Date', flex: 1.5, sortable: false},
    {field: 'principle', headerName: 'Balance', flex: 1.3, sortable: false},
    {field: 'totalPayment', headerName: 'Payment', flex: 1.3, sortable: false},
    {field: 'interestPayment', headerName: 'Interest', flex: 1.3, sortable: false},
    {field: 'principlePayment', headerName: 'Principle', flex: 1.2, sortable: false},
    {field: 'newPrinciple', headerName: 'Debt Remaining', flex: 1.75, sortable: false},
  ];

  return (
    <div style={{overflowX: "scroll"}}>
      <div style={{
        height: 418,
        width: "100%",
        minWidth: "850px",
        marginBottom: "50px",
        marginTop: "30px",
        backgroundColor: "white",
      }}>
        <div style={{width: "100%", height: "100%"}}>
          <XGrid
            rows={props.tableData}
            columns={columns}
            rowHeight={38}
            hideFooter
            disableColumnReorder={true}
            disableColumnFilter
          />
        </div>
      </div>
    </div>)
}
